import React from "react";
import Header from "../components/header";
import Menu from "../components/Menu";
import Footer from "../components/footer";
import ReactHtmlParser from "react-html-parser";
import {Helmet} from "react-helmet";
import globe from "../globals"
import FixedInfoButton from "../components/FixedInfoButton";
import PopupModel from "../components/popup-model";
import FormResponseModel from "../components/form-response-model";
import images from "../components/images";


export default function Page({ pageContext }) {
    const { page } = pageContext;

    let yoast_head = globe.domainReplace(page.yoast_head)
    let temp = yoast_head.match(/<script.+?<\/script>/g)[0] || ''
    let schema = temp !== undefined && temp !== null && temp.length > 70 ? temp.substring(62, temp.length-9) : {}
    let des = page.excerpt.substr(3, page.excerpt.length - 8) || '';


    return (
        <>
            <Helmet>
                <html lang="en" />
                <title>{page.title}</title>

                {page.yoast_head.match(/name="description"/g) === null ? <meta name="description" content={des}/> : null}
                { ReactHtmlParser(yoast_head) }

                <meta property="og:image" content={images.aboutUs} data-react-helmet="true" />

                <script type="application/ld+json">
                    {schema}
                </script>

            </Helmet>
            <Header/>
            <Menu/>

            <div className="section-title pt-5">
                <h1 className="section-title-heading fsm-theme-heading" dangerouslySetInnerHTML={{ __html: page.title }} />
            </div>


            <div className="container-fluid pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="fsm-theme-text text-justify" dangerouslySetInnerHTML={{__html: page.content}} />
                        </div>
                    </div>
                </div>
            </div>


            <Footer/>
            <FixedInfoButton/>
            <PopupModel name={"formModal"}/>
            <FormResponseModel />

        </>
    )
}
